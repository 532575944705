import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': '1EAEA1BE-1D17-4DD1-B0AE-76DBBCA4FDAF'
  }
})

export const ANALYTICS_API = axios.create({
  baseURL: config.ANALYTICS_URL,
  headers: {
    apiKey: '93A26D8F-61D1-4AEE-AD69-B5982076BF0B'
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  store: {
    list: () => BE_API.get('/stores')
  },
  scan: {
    details: (code, storeId, qty, zipCode) => BE_API.get('/scan', { params: { code, storeId, qty, zipCode } })
  },
  tracking: {
    get: (orderNumber, storeId) => BE_API.get('/tracking', { params: { orderNumber, storeId } })
  },
  analytics: {
    save: body => ANALYTICS_API.post('/analytics', body)
  }
}
export default apis
