const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-b2c-promise-nprd-ping-fe3z46pnpa-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080',
    ANALYTICS_URL: 'https://common-analytics.test.lmit.app/api/v1',
    SCANDIT_LICENSE_KEY:
      'AWIwiD54HUXvQf/Iwxtn6jQ3xOkdG0g9WQnsEYpaVdsPFbqsYU5QF1RL09CMTMcPpGNdWXdhWYGVMxlMmBGyZFttoJZ2IQQPWUewybd9VLr0XHraxk+qNvVxMDLCdSSmRxZXiJsty5JyJuKGUSjxaJaMLEfdmUyLiDyB8vtxDQRFuWJx/+sLfeF8I2wB/C4irroGsFJ4umM9v0PJ3u4irKqeKEcvhOzoGBaBlzvD7YkjwQj+s4fP3Akgt76wg2vB3YKlKdPMkAznyJHE1uBO7bi9L5OlaNlPjpkoP2iGTSVBmr1+Jo+vT9V4qVEZbVGIt8W7vAtWpKuSgRWneMJ48xKF8H22njephWmi29HNI7sijgo6sjrU++GN2SUVzK0YofyIQlkWZIep65f+NIxqh+n+PS0WDhQS/SrO1nVf7rFuGdKkInrGZQ36ph4jnED3mliR+zJG5yUWHMRKeczPamXaKB3NgYyIH3pyVFEi4h0iie0qAzTdhcaETROWOduhkKPCmQbsNnzUc0kZEvqr7T5uzbLKByEq3DHLKWlX/EhB5RkxGZXii9PjdwJaEiXRezlaVtmAh2je5B6CF02Xd+sAenpyQu0nfGk7HDxy7Nz/zSdb+ChbG0XFpa+uTz4nGoMyFProjIQzsdESB8vA1EQ/xbW572KO0Q1MR4dovWFs5+tI9c6abzcerYwi78/HBg30K6IVGfAXNBWYQGlHbOdKX3DxGG3HxHzC24vVl62+HBotqQ8eampF0O//qZVcdBUP8mFOFSAH4hYVPa4mT6Xmq8a3FKsIh1AKSKwdFT6TTAgWZDoyGo09OKtV0cE=' //ngrok
  },
  test: {
    LOGIN_URL: `https://test-lmit-b2c-promise-nprd-ping-fe3z46pnpa-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-b2c-promise-api-fe3z46pnpa-ew.a.run.app',
    ANALYTICS_URL: 'https://common-analytics.test.lmit.app/api/v1',
    SCANDIT_LICENSE_KEY: 'AfIy4mCjRVVZIumlMyAX4JEtIT1+Bpuvp1kCl0d1ePN7cp5Vh0S27lh9f3CXfY0SM11hAeQuqFwjcv4wbVtn97hCkks/RcVMq32FIpMn26+wZy9qx1ryeZE8XMXMf7sjsG2pCTh4F5ITRNw9ZiBhKQcrl2v5bVzEi3GaCEVAZdVQCeBWnDqfoNwXnPBjjRinv8BU3UUpr4PegK5QGND9YllJOp8GUMI3eH8mPSGJMXL7MHVh44iCJVbbL34sKZXEF8JCvjADUCPko1GCq50EtM5yO847Ha2S9VxtabavzReZO04C2so7Y9Ynu5hlg2e0VUsqzjOQKqZ/EFT50RagTCm9ZNRfeDaKLUFfkKm7H/mJmxKG0YZh+BLorg00yeQuk2FCUOjU/qEuswo42X67cLLk+XywbS7XQ2racclDqKJ03Z3wxyi9oST8b/0UOZAC+CStoOsSXgEwfmgo8aCHuQXSR409VGb0dXOxyrRVPRlEkuqJTv38VMF7TEeQ8ctFMcYSnYQhabth7STtkd9gKOL2LnpFmi85dbv4Vi85nkzTf9oZCQvT3F9StKovifvr8BgKsndSFBHgXudQzeGeiEczTDZUkkvWP7msbY3DuL/QZTbCP/3vUPNNzgTpz5HYJp1Jnu0uchpeP3vk9/ySnmTrBCjkQy90eOKrYU9W3V9XgjnsgCoz7pErvzvIycfo0Y30ZSLqokPU18g62XfeG83YuTf3slvRDcva/CWCYprXKEMu/cpXHm6BbO31V3ZBfwTq/9bs4h5bNMz2A5ogrxX4ZP3ImFj4H2JCEhSgF9ycvU1HPEfD0ddEd5SVdOWzE5I1bC77z9P91pPrmTC/rs1IxOd/keTmgZDkgQ==' // https://promise.test.lmit.app
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-b2c-promise-prd-ping-emron4hjdq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-b2c-promise-api-emron4hjdq-ew.a.run.app',
    ANALYTICS_URL: 'https://common-analytics.lmit.app/api/v1',
    SCANDIT_LICENSE_KEY: 'AQBSmpeBA3N+AW7EyhY+wvM1s1uqEE8/QlBnerpX1WN5MQw4eWaInV5rWaSuJZNAriLHvAtJ9dxjDdG2R0pK4EVnBqoQQ+o1r0cxa7427vSDd9amSFo57fdSJ97JV5Wvp0qJ8KcadwJYXf1t5W6kb5MS0yx0E2THTwyMs8M2c21cKjJPBWTQh7RzxICS/BH4vBzCSm39asLXTfPxM1BbHL2Rm4bnESnYXYH7GQnR6v7CdmnN3yLAUuhexciAiUVY5B1C4j37xDVdAe5QkRc3DHyk22qCvRp79woOdprPXLu6a1ichPNw8H6pgHdbD/2PRJgMoyiEOOV4gmAAC4wsldrEuA281YodjqfHoxPsnFIiEkeTgkfV//yDKPFiv2U7Oym5+VlWBqQ0MlNXgTztNm/nNnIgU19l59uHwXK4Qm7oKiavHaNDGXKzVd183SdwKY4m0isPd/8DtJMvn5mHgkP14j8joSFJUnqtLwbFZWUFDpKW/asju+8kL0nSpss/epdu/cb61r8/WakE8+wadyzAU2R09WwAjHWU3Hd+A6OHKdKjE6weduPXaMRRpYNpopqzJVz0rZhT+ATz+P7IKTBEe+ie1fQ9RpPd3CZJYCxk9hrvvm+bcX/ag9Enyw1E1FQgJDqgSlwDvDqDLX221z6qnbjC+L6UD1cH9a2iWnyN52z+dBJ2CPOJ2nHeOzdlLmjyfWVnomhVCHr/ULGdVteF5oromp1384r72fWKYdSo6hO+jiHnQDEsvjNRWZY+HjiRAxVS5J+ubMR+fUJeHexNf7u5JsN6FNLNdexXtCD7qyxv0Ni9iFjr/bA9ULqkWoB6JVCRyw7DvnM14jJIPA==' // https://promise.lmit.app
  }
}

export default envConfig[env]
